import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CurrencyPipe } from '@app/shared/currency.pipe';
import { UtilsService } from '@app/shared/utils.service';
import { NavigationBarModule, RandomColorModule } from '@infomaniak/angular-common';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxsModule } from '@ngxs/store';

import { NavigationService } from '../core/navigation/navigation.service';
import { CartState } from '../store/state/cart.state';
import { CarouselComponent } from './carousel/carousel.component';
import { CircleIconComponent } from './circle-icon/circle-icon.component';
import { LoaderComponent } from './loader/loader.component';
import { FilterPipe } from './search.pipe';
import { ShopCardComponent } from './shop-card/shop-card.component';
import { ShopPromoChipComponent } from './shop-promo-chip/shop-promo-chip.component';
import { SizePipe } from './size.pipe';
import { StepSubtitleComponent } from './step-subtitle/step-subtitle.component';
import { StepTitleComponent } from './step-title/step-title.component';
import { VatSwitchComponent } from './vat-switch/vat-switch.component';
import { CardTemplateComponent, IncludePipe } from '@app/shared/card-template/card-template.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteTriggerAccessor } from '@app/shared/matAutocompleteTriggerAccessor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomLocalizedDatePipe } from './customLocalizedDate.pipe';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaxUserCommercialContactComponent } from './max-user-commercial-contact/max-user-commercial-contact.component';
import { DiscountTagComponent } from '@app/shared/discount-tag/discount-tag.component';
import { TooltipModule } from '@infomaniak/ngx-tooltip-directive';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslocoModule,
    NgxsModule.forFeature([CartState]),
    MatChipsModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    TooltipModule,
  ],
  declarations: [
    LoaderComponent,
    CurrencyPipe,
    SizePipe,
    FilterPipe,
    CarouselComponent,
    StepTitleComponent,
    StepSubtitleComponent,
    ShopCardComponent,
    CircleIconComponent,
    ShopPromoChipComponent,
    VatSwitchComponent,
    CardTemplateComponent,
    IncludePipe,
    MatAutocompleteTriggerAccessor,
    CustomLocalizedDatePipe,
    ExpansionPanelComponent,
    MaxUserCommercialContactComponent,
    DiscountTagComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    TranslocoModule,
    LoaderComponent,
    StepTitleComponent,
    StepSubtitleComponent,
    ShopCardComponent,
    CircleIconComponent,
    ShopPromoChipComponent,
    CurrencyPipe,
    SizePipe,
    FilterPipe,
    CarouselComponent,
    MatMenuModule,
    NavigationBarModule,
    MatOptionModule,
    MatSelectModule,
    RandomColorModule,
    VatSwitchComponent,
    CardTemplateComponent,
    IncludePipe,
    MatAutocompleteTriggerAccessor,
    CustomLocalizedDatePipe,
    ExpansionPanelComponent,
    MaxUserCommercialContactComponent,
    DiscountTagComponent,
  ],
  providers: [NavigationService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [UtilsService],
    };
  }
}
