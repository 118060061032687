import { animate, style, transition, trigger } from '@angular/animations';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService, Logger } from '@app/core';
import { Item } from '@app/models/item';
import { Navigation } from '@app/models/navigation';
import { RemoveItem, RemoveItemOption, RemoveSubItem, UpdateItem, UpdateSubItem } from '@app/store/actions/cart.action';
import { CartState } from '@app/store/state/cart.state';
import { environment } from '@env/environment';
import ikPrice from '@infomaniak/currency';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { ScrollService } from './core/scroll.service';
import { CartStatus } from './models/cart';
import { SwitchAccount } from './store/actions/account.action';
import { SetLangSuccessful } from './store/actions/cart.action';
import { translate, TranslocoService } from '@ngneat/transloco';
import { urlIncludes, WHITE_BACKGROUND_URLS } from '@app/shared/helpers/url';
import { MatomoTrackerService } from '@app/core/matomo-tracker.service';
import {CaptchaHandlingService} from "@app/shared/services/captcha-handling/captcha-handling.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
    ]),
    trigger('enterAnimationHeader', [
      transition(':enter', [style({ opacity: 0 }), animate('700ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('700ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  account: any;
  mode = new FormControl('over');
  hiddenSidenav = true;
  lang = window['CONST_LANG'].shortcode;
  addCss = false;

  @Select(CartState.accounts)
  accounts$: Observable<any>;
  showGoTop = false;
  // links
  @Select(CartState.links)
  links$: Observable<any>;

  @Select(CartState.status)
  status$: Observable<CartStatus>;

  // current nav route
  @Select(CartState.navigation)
  navigation$: Observable<Navigation>;
  activeRoute: number;
  activeRouteConfiguration: any;

  @Select(CartState.items)
  item$: Observable<Item[]>;
  items: Item[];

  tracked = false;
  _accounts: any[];
  private onDestroy$ = new Subject<boolean>();

  displayVisualChallengeOverlay$: Observable<boolean> ;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslocoService,
    private i18nService: I18nService,
    private location: PlatformLocation,
    private actions: Actions,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private scroll: ScrollService,
    public scroll2: ScrollDispatcher,
    private _ngZone: NgZone,
    private matomoTrackerService: MatomoTrackerService,
    private captchaHandlingService: CaptchaHandlingService,
  ) {
    this.accounts$.pipe(takeUntil(this.onDestroy$)).subscribe((accounts) => {
      this._accounts = accounts;
    });

    this.displayVisualChallengeOverlay$ = this.captchaHandlingService.visualChallengeDisplayed$;

    this.account = window['CURRENT_ACCOUNT'];

    this.navigation$.pipe(takeUntil(this.onDestroy$)).subscribe((nav) => {
      this.showGoTop = document.body.scrollTop >= 66;

      if (nav && nav.workflow) {
        this.activeRoute = nav.current_step;
        this.activeRouteConfiguration = nav.workflow.find((x) => x.order === this.activeRoute).configuration || [];
      }
    });

    if (window['CONST_CURRENCY']?.label) {
      const currency = window['CONST_CURRENCY'].id === 1 ? 'chf' : 'euro';
      ikPrice.init({ currency });
    }

    // Enable matomo only in production with non-staff users
    // if (environment.production && !window['IS_STAFF']) {
    // Init matomo tracking
    this.matomoTrackerService.init(environment.matomoUrl, environment.matomoAppId);
    // }
  }

  get hasWhiteBackground(): boolean {
    return urlIncludes(WHITE_BACKGROUND_URLS);
  }

  isDuringOrder() {
    return window['CURRENT_STATE'].includes('order');
  }

  scrollToTop() {
    this.scroll.scrollToTop();
  }

  findItem(id, sub_item = false): any {
    const allItemsAndSubItems = this.getAllItemsAndSubItemsArray(this.items);
    const selectedItem = allItemsAndSubItems.find((item: Item) => item.setup.id === id);

    return selectedItem;
  }

  getAllItemsAndSubItemsArray(items: any) {
    const allProducts = [];

    for (const item of items) {
      allProducts.push(item);

      if (item.sub_items) {
        for (const sub_item of item.sub_items) {
          const subItem = cloneDeep(sub_item);
          subItem.setup.parentId = item.setup.id;
          allProducts.push(sub_item);
        }
      }
    }
    return allProducts;
  }

  updateItem(event: CustomEvent) {
    const item = cloneDeep(this.findItem(event.detail.id));
    item.attributes = { ...item.attributes, ...event.detail.body };
    if (event.detail.parentId) {
      const parent = this.findItem(event.detail.parentId);
      this.store.dispatch(new UpdateSubItem({ parent, subitem: item }));
    } else {
      this.store.dispatch(new UpdateItem(item));
    }
  }

  removeItem(event: CustomEvent) {
    const item = this.findItem(event.detail.id);
    if (event.detail.parentId) {
      const parent = this.findItem(event.detail.parentId);
      this.store.dispatch(new RemoveSubItem({ parent, subitem: item }, true));
    } else {
      this.store.dispatch(new RemoveItem(item, true));
    }
  }

  removeItemOption(event: CustomEvent) {
    console.warn(event);
    this.store.dispatch(new RemoveItemOption(this.findItem(event.detail.id), event.detail.type));
  }

  ngOnInit() {
    const listener = (event: CustomEvent) => {
      if (event.detail === 'cart') {
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: this.store.selectSnapshot(CartState.getCart),
          })
        );
        document.removeEventListener('WCDidLoad', listener);
      }
    };
    document.addEventListener('WCDidLoad', listener);

    document.addEventListener('WCupdateItem', (event: CustomEvent) => this.updateItem(event));

    document.addEventListener('WCremoveItem', (event: CustomEvent) => this.removeItem(event));

    document.addEventListener('WCremoveItemOption', (event: CustomEvent) => this.removeItemOption(event));

    setTimeout(() => {
      this.addCss = true;
    }, 5000);
    if (window.location.hash.includes('hadError')) {
      window['hadError'] = true;
    }

    // if (window['IS_AUTOLOG']) {
    //     alert('Vous ne pouvez pas etre autolog.');
    //     window.location.replace('https://www.infomaniak.com');
    // }

    this.location.onPopState((e) => {
      // in case of the user use history back, redirect the user on the base / (handled by navigationService after that)
      // this.store.dispatch(new SetNavigation({ current_step: null, target: -1 }));
    });

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.langChanges$, onNavigationEnd, this.actions.pipe(ofActionSuccessful(SetLangSuccessful)))
      .pipe(
        map(() => {
          window.scrollTo(0, 0);
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => {
        const welcomeTitle = translate('Bienvenue ! Nous sommes ravis de faire votre connaissance');
        const shopTitle = 'Shop';
        this.titleService.setTitle(
          '🚀 ' + (window.location.hostname.includes('welcome.') ? welcomeTitle : shopTitle) + ' - Infomaniak'
        );
      });
    this.lang = this.i18nService.language;

    document.body.onscroll = () => {
      this.showGoTop = document.body.scrollTop >= 66;
    };

    this.item$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => {
      if (items) {
        this.items = items;
      }
    });

    this.initGTM();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    document.removeEventListener('WCupdateItem', (event: CustomEvent) => this.updateItem(event));
    document.removeEventListener('WCremoveItem', (event: CustomEvent) => this.removeItem(event));
    document.removeEventListener('WCremoveItemOption', (event: CustomEvent) => this.removeItemOption(event));
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  initGTM() {
    const isCustomUrl = window['USE_CUSTOMIZED'] ?? false;
    if (!this.tracked && !window.location.host.includes('infomaniak.ch') && !isCustomUrl) {
      this.tracked = true;
      const loadGtm = function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });

        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&amp;l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        if (window['APP_ENV'] === 'production') {
          j.src += '&gtm_auth=d-ke9GyPSkVmBsyMErF8Ew&gtm_preview=env-2&gtm_cookies_win=x';
        } else {
          j.src += '&gtm_auth=3RhEO4L5C-A-r4p7dHs-kQ&gtm_preview=env-341&gtm_cookies_win=x';
        }
        f.parentNode.insertBefore(j, f);
      };
      loadGtm(window, document, 'script', 'dataLayer', 'GTM-NWNCSS');
    }
  }

  setAccount(id: string) {
    this.store.dispatch(new SwitchAccount({ account_id: id, context: 'menu' }));
  }
}
