<app-header *ngIf="(links$ | async)"></app-header>
<div [class.full-height-with-header]="!(links$ | async)"
     class="full-height-without-header"
     [class.white-background]="hasWhiteBackground">
  <router-outlet></router-outlet>
</div>
<div class='wc-trigger-reporting-tools'>
  <module-reporting-tools-component></module-reporting-tools-component>
</div>
<div [class.support--bottom]=" ((!isDuringOrder() || (activeRouteConfiguration &&
     activeRouteConfiguration['hide_bottom_bar'] && !activeRouteConfiguration['child_callback_for_bottom_bar']) ||
     ((activeRouteConfiguration && !activeRouteConfiguration['child_callback_for_bottom_bar']) && (status$ | async) ===
     'draft')))"
     class="support desktop-only"
>
  <div class="support__container">
    <div *ngIf="showGoTop"
         [@enterAnimation]
         class="support__top">
      <button (click)="scrollToTop()"
              class="back-to-top">
        <span class="icon icon-chevron-up icon-size-15 white"></span>
      </button>
    </div>
  </div>
</div>

<!-- We use the display css proeprty instead of an if, because we need to be able to select the wrapper immediately after trying to display it -->
<div class="captcha-wrapper" [style.display]="(displayVisualChallengeOverlay$ | async) ? 'flex' : 'none'">
    <div id="grecaptcha-wrapper"></div>
</div>
