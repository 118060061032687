<header #top
        [class.white-background]="hasWhiteBackground"
        class="header">
    <div class="header__content">
        <ik-header-title [class.ik-header-title-left-logged]="!isLastStep && currAccount"
                         [class.ik-header-title-left-unlogged]="!isLastStep && !currAccount"
                         [class.padding-left-10]="isMobile"
                         [companyName]="currAccount && currAccount.organization_name"
                         [iconClass]="'icon-chevron-down'"
                         [logoCompany]="(currAccount && currAccount.logo) || infomaniakLogoPath"
                         [logo]="'/assets/images/logo-manager.svg'"
                         [mode]="isMobile ? 'mobile' : 'desktop'"
                         [productName]="productName"
                         [url]="null"
                         class="light-theme"></ik-header-title>
        <div class="header__content--navbar">
            <ik-navigation-bar (change)="tryNavigateFromNavigationBar($event)"
                               *ngIf="!isMobile && displayedSteps && displayedSteps.length > 0"
                               [canClickBackward]="true"
                               [canClickForward]="false"
                               [config]="displayedSteps"
                               [selected]="displayedActiveRoute"></ik-navigation-bar>
        </div>
        <nav class="header__nav">
            <ul class="header__menu list list--flex">
                <vat-switch *ngIf="vatToggleConfig.country_has_vat && orderState && !isTablet && !isLastStep"
                            [config]="vatToggleConfig"></vat-switch>
                <li (click)="goToAutologout()"
                    *ngIf="isAutolog"
                    class="header__menu-item autolog"
                    title="autologout">
                    <span class="icon icon-users-minus icon-size-15 white"></span>
                </li>
                <module-cart-component [hidden]="isLastStep" (click)='onShowCart()'></module-cart-component>
                <div *ngIf="!isMobile" class="ik-header-link">
                    <module-products-component id="menu" position="left">
                        <span class="icon icon-layout-module icon-size-20" slot="trigger"></span>
                    </module-products-component>
                </div>

                <li class="header__menu-item header__menu-item--nohover header__language">
                    <mat-form-field class="lang">
                        <mat-select [(ngModel)]="config.language"
                                    class="langSelect">
                            <mat-option (click)="setLanguage(language)"
                                        *ngFor="let language of languages"
                                        [value]="language"
                                        class="header__language-options"
                                        mat-option>
                                {{ language }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
                <li class="header__menu-item">
                    <div *ngIf="!isLogged && !isMobile"
                         class="header__login"
                         placement="bottom-right">
                        <a href="/auth/login?project=shop">{{'S\'identifier' | transloco}}</a>
                    </div>
                    <div *ngIf="!isMobile && currAccount && user && isLogged">
                        <module-menu-user id="menu-user">
                            <a *ngIf="currAccount && user && isLogged"
                               class="header__link"
                               slot="trigger">
                                <div *ngIf="user.avatar_url"
                                     class="header__avatar">
                                    <img [src]="user.avatar_url"
                                         alt="{{ user.first_name }} {{ user.last_name }}"/>
                                </div>
                                <div *ngIf="!user.avatar_url"
                                     [ngClass]="{'no-avatar': !user.avatar_url}"
                                     [randomColorId]="user.id"
                                     [randomColor]="user.first_name + ' ' + user.last_name"
                                     class="header__avatar">
                                    <span>{{ getInitial() }}</span>
                                </div>
                            </a>
                        </module-menu-user>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</header>
<ng-template #logoInfomaniak>
    <div class="logo-infomaniak">
        <div>
            <img/> LOGO
        </div>
        <div>
            <img/>Infomaniak <span>shop</span>
        </div>
    </div>
</ng-template>
